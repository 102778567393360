@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(var(--translate-y-from, 10px));
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.fade-in-up {
	animation: fadeInUp 0.4s ease-out;
	opacity: 0;
	animation-fill-mode: forwards; /* stays visible after animation */
}

@keyframes scaleFadeIn {
	from {
		opacity: 0;
		scale: 0;
	}

	to {
		opacity: 1;
		scale: 1;
	}
}

.scale-fade-in {
	animation: scaleFadeIn 0.15s easing(ease-out-quad);
	animation-fill-mode: forwards;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fade-in {
	animation: fadeIn 0.15s easing(ease-out-quad);
	animation-fill-mode: forwards;
}
